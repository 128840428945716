import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"

import { color, mq } from "../../utils/style-components"

type ConfirmProps = {
  backToForm: () => void
}
const Confirm: React.FC<ConfirmProps> = ({ backToForm }) => {
  const { getValues } = useFormContext()
  const values = getValues()
  const nl2br = (str: string) => {
    if (!str) return
    const regex = /([\n\r])/g
    return str.split(regex).map((line: string) => {
      return line.match(regex) ? <br /> : line
    })
  }
  return (
    <>
      <ConfirmRow label="お名前" required>
        {values.name}
      </ConfirmRow>

      <ConfirmRow label="メールアドレス" required>
        {values.email}
      </ConfirmRow>

      <ConfirmRow label="社名・所属">{values.company}</ConfirmRow>

      <ConfirmRow label="電話番号">{values.tel}</ConfirmRow>

      <ConfirmRow label="URL">{values.url}</ConfirmRow>

      <ConfirmRow label="お問合せ内容">{nl2br(values.message)}</ConfirmRow>
      <div
        css={css`
          margin-top: min(60px, 6vw);
          display: flex;
          justify-content: center;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            align-items: center;
            font-weight: bold;
            &::before {
              display: inline-block;
              content: "";
              width: 16px;
              height: 16px;
              border: solid 1px ${color.font};
              margin: 2px 0.8em 0 0;
            }
            &::after {
              display: block;
              content: "";
              position: absolute;
              top: 45%;
              left: 0;
              width: 15px;
              height: 10px;
              border-left: 3px solid ${color.blue};
              border-bottom: 3px solid ${color.blue};
              transform-origin: 0 0;
              transform: rotate(-45deg);
            }
          `}
        >
          <span>個人情報の取り扱いに同意する</span>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding: min(87px, 12vw) 0 0;
          ${mq(
            `sp`,
            css`
              align-items: center;
              flex-direction: column;
            `
          )}
        `}
      >
        <input
          type="reset"
          value="戻る"
          onClick={backToForm}
          css={css(
            buttonStyle,
            css`
              background: ${color.gray};
              ${mq(
                `pc`,
                css`
                  margin-right: 50px;
                `
              )}
              ${mq(
                `sp`,
                css`
                  margin-bottom: 4vw;
                `
              )}
            `
          )}
        />
        <button
          type="submit"
          css={css(
            buttonStyle,
            css`
              background: ${color.blue};
            `
          )}
        >
          送信
        </button>
      </div>
    </>
  )
}

const ConfirmWrap = styled.div`
  margin-top: min(40px, 5vw);
  &:first-of-type {
    margin-top: 0;
  }
`

type ConfirmRowProps = {
  label: string
  required?: boolean
}
const ConfirmRow: React.FC<ConfirmRowProps> = ({
  label,
  required,
  children,
}) => (
  <ConfirmWrap>
    <div>
      <span
        css={css`
          font-weight: bold;
          ${required &&
          `
            &::after {
              content: '※';
              color: ${color.red};
              margin-left: 0.3em;
            }
          `}
        `}
      >
        {label}
      </span>
      <div
        css={css`
          padding-left: 1em;
        `}
      >
        {children}
      </div>
    </div>
  </ConfirmWrap>
)
const buttonStyle = css`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
  color: ${color.white};
  width: 270px;
  height: 70px;
`

export default Confirm
