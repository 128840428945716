import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { scroller, Element } from "react-scroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ContactWrap,
  ContactInner,
  ContactTitle,
} from "../components/contact/contact-parts"
import ContactForm from "../components/contact/contact-form"
import Confirm from "../components/contact/confirm"
import Sending from "../components/contact/sending"
import Sent from "../components/contact/sent"

interface IFormValues {
  name: string
  email: string
  company: string
  tel: string
  url: string
  message: string
  agreement: boolean
}

type PageStateType = "form" | "confirm" | "sending" | "sent"
const Contact: React.FC = () => {
  const methods = useForm<IFormValues>()
  const { handleSubmit, reset } = methods

  const [pageState, setPageState] = useState<PageStateType>("form")
  const [isSendError, setIsSendError] = useState(false)

  const scrollToFormTop = () => {
    scroller.scrollTo(`formTop`, {
      duration: 800,
      delay: 0,
      offset: -300,
      smooth: `easeOutQuint`,
    })
  }

  const apiURL = process.env.GATSBY_API_URL || ""
  const sendFormData = (data: IFormValues) => {
    fetch(`${apiURL}/api/contact/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response is not ok.`)
        }
        return response.json()
      })
      .then(data => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(data)
        }
        if (!data.mailStatus.status) {
          throw new Error(`Message send failure.`)
        }
        setPageState("sent")
        reset()
        scrollToFormTop()
      })
      .catch(error => {
        if (process.env.GATSBY_ENV === `develop`) {
          console.log(
            "There has been a problem with your send operation:",
            error
          )
        }
        setIsSendError(true)
        setPageState("form")
        scrollToFormTop()
      })
  }

  const onSubmit = (data: IFormValues) => {
    switch (pageState) {
      case "form":
        setIsSendError(false)
        setPageState("confirm")
        scrollToFormTop()
        break
      case "confirm":
        setPageState("sending")
        scrollToFormTop()

        sendFormData(data)

        break
    }
  }

  const backToForm = () => {
    setPageState("form")
    scrollToFormTop()
  }

  return (
    <Layout>
      <SEO
        title="Contact　お問い合わせ"
        description="プレス関係、行政関係、起業のSDGsご担当の方はこちらからお問合せ下さい。"
      />
      <ContactWrap>
        <ContactInner>
          <ContactTitle>Contact</ContactTitle>
          <Element name="formTop" />
          {pageState === "sent" && <Sent />}
          {pageState === "sending" && <Sending />}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {pageState === "form" && (
                <ContactForm isSendError={isSendError} />
              )}
              {pageState === "confirm" && <Confirm backToForm={backToForm} />}
            </form>
          </FormProvider>
        </ContactInner>
      </ContactWrap>
    </Layout>
  )
}

export default Contact
