import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { color, width, fontFamily, mq } from "../../utils/style-components"
import { color as csxColor } from "csx"

import HexBg from "../../img/common/hex_bg.svg"

export const ContactWrap = styled.div`
  overflow: hidden;
  background: top left / 461px 180px url(${HexBg}) ${color.grayLight};
`
export const ContactInner = styled.div`
  position: relative;
  margin: min(274px, 50vw) auto min(380px, 20vw);
  padding: min(120px, 16vw) min(66px, 2.5vw) min(90px, 14vw);
  max-width: ${width.contentInner};
  width: ${width.contentSP};
  background: ${csxColor(color.white).fade(0.75).toString()};
`

export const ContactTitle = styled.h2`
  position: absolute;
  left: 0;
  font-family: ${fontFamily.decorate1};
  font-weight: normal;
  line-height: 1;
  color: ${color.blue};
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  ${mq(
    `pc`,
    css`
      font-size: 8rem;
      top: -4rem;
    `
  )}
  ${mq(
    `sp`,
    css`
      font-size: 4.2rem;
      top: -2rem;
    `
  )}
`

